<template>
  <div id="cart">
    <Header></Header>
    <div class="content-wrapper">
      <div class="breadcrumb-wrap bg-f br-1">
        <div class="overlay bg-black op-9"></div>
        <img :src="shape1" alt="Image" class="br-shape-1" />
        <img :src="shape2" alt="Image" class="br-shape-2" />
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="breadcrumb-title">
                <h2>Cart</h2>
                <ul class="breadcrumb-menu list-style">
                  <li><a href="">Home </a></li>
                  <li>Cart</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="wishlist-wrap pt-100 pb-75">
      <div class="container">
        <div class="row">
          <div class="col-md-12 mb-20">
            <div class="wishlist-table hidden-xs">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Product Price</th>
                    <th scope="col">Remove</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(it, index) in submitOrder.order" :key="index">
                    <td>
                      <div class="wh_item">
                        <img
                          :src="baseUrl() + it.image"
                          :alt="it.name"
                          onError="this.src='./assets/img/New-Project.jpg';"
                        />
                        <div class="wh-item-info">
                          <router-link :to="`/shopdetails/${it.id}`">{{
                            it ? it.name : ""
                          }}</router-link>
                          <table
                            v-if="
                              it.selectedaddons && it.selectedaddons.length > 0
                            "
                            align="center"
                          >
                            <tr>
                              <th>Addon</th>
                              <th>Price</th>
                            </tr>
                            <tr
                              v-for="addon in it.selectedaddons"
                              :key="addon.id"
                            >
                              <td>{{ addon.addon_name }}</td>
                              <td>{{ addon.price }}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="wh_qty">
                        <div class="product-quantity style2">
                          <div class="qtySelector">
                            <span
                              class="las la-minus decreaseQty"
                              @click="minusQuantity(it, index)"
                            ></span>
                            <span class="qtyValue">{{
                              add_quantity[index]
                            }}</span>
                            <span
                              class="las la-plus increaseQty"
                              @click="addQuantity(index)"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="price" v-if="it.addOnTotal">
                      ${{ it ? it.addOnTotal.toFixed(2) : "" }}
                    </td>
                    <td class="price" v-else>${{ it ? it.price : "" }}</td>
                    <td>
                      <button type="button" @click="deleteItem(index)">
                        <i class="las la-times"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="card hidden-md">
              <ul
                class="list-group list-group-flush"
                v-for="(it, index) in submitOrder.order"
                :key="index"
              >
                <li class="list-group-item">
                  <div class="continer">
                    <div class="row">
                      <div class="col-md-6 box">
                        <img
                          :src="baseUrl() + it.image"
                          :alt="it.name"
                          onError="this.src='./assets/img/New-Project.jpg';"
                          class="img-thumbnail w-50"
                        />
                      </div>
                      <div class="col-md-6 box">
                        <div class="row">
                          <div class="col-md-6 box">
                            <div class="wh_qty">
                              <div class="product-quantity style2">
                                <div class="qtySelector">
                                  <span
                                    class="las la-minus decreaseQty"
                                    @click="minusQuantity(it, index)"
                                  ></span>
                                  <span class="qtyValue">{{
                                    add_quantity[index]
                                  }}</span>
                                  <span
                                    class="las la-plus increaseQty"
                                    @click="addQuantity(index)"
                                  ></span>
                                </div>
                              </div>
                            </div>
                            <span
                              class="badge remave rounded-pill bg-danger text-end"
                              @click="deleteItem(index)"
                              ><i class="las la-times"> Remove</i></span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 box">
                        <router-link :to="`/shopdetails/${it.id}`">{{
                          it ? it.name : ""
                        }}</router-link
                        ><br />
                        ${{ it ? it.price : "" }}
                      </div>
                      <div class="col-md-6 box"></div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="mb-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="checkout-details smt-30">
              <div class="content-box-title">
                <h4 class="mb-20">Total Bill</h4>
              </div>
              <div class="bill-details">
                <div class="subtotal-wrap">
                  <div class="subtotal-item">
                    <div class="subtotal-item-left">
                      <h6>Order total</h6>
                    </div>
                    <div class="subtotal-item-right">
                      <span>${{ orderTotal.toFixed(2) }}</span>
                    </div>
                  </div>
                  <div
                    class="subtotal-item"
                    v-if="orderTotal >= 10 && discountPrice > 0"
                  >
                    <div class="subtotal-item-left">
                      <h6>Discount</h6>
                    </div>
                    <div class="subtotal-item-right">
                      <span>${{ discountPrice }}</span>
                    </div>
                  </div>
                  <div
                    class="subtotal-item"
                    v-if="deliveryCharges == 1 && delivery_amount > 0"
                  >
                    <div class="subtotal-item-left">
                      <p>Delivery Charges</p>
                    </div>
                    <div class="subtotal-item-right">
                      <p>${{ delivery_amount }}</p>
                    </div>
                  </div>
                  <div class="subtotal-item">
                    <div class="subtotal-item-left">
                      <p>
                        Total Tax({{
                          tipTax ? tipTax.taxPercentage.value : ""
                        }}%)
                      </p>
                    </div>
                    <div class="subtotal-item-right">
                      <p>${{ taxTotal ? taxTotal.toFixed(2) : 0 }}</p>
                    </div>
                  </div>
                </div>
                <div
                  class="total-wrap"
                  v-if="deliveryCharges == 1 && delivery_amount > 0"
                >
                  <h5>Total Amount</h5>
                  <span
                    >${{
                      totalAmount
                        ? (
                            parseFloat(totalAmount.toFixed(2)) +
                            parseFloat(submitOrder.tipAmount) +
                            parseFloat(delivery_amount.toFixed(2))
                          ).toFixed(2)
                        : 0
                    }}</span
                  >
                </div>
                <div class="total-wrap" v-else>
                  <h5>Total Amount</h5>
                  <span
                    >${{
                      totalAmount
                        ? parseFloat(totalAmount.toFixed(2)) +
                          parseFloat(submitOrder.tipAmount.toFixed(2))
                        : 0
                    }}</span
                  >
                </div>
              </div>
              <div class="col-lg-12 mt-3">
                <!--<a href="checkout.html" class="btn style1 w-100 d-block"
                  >Proceed To Checkout<i class="lar la-check-circle"></i
                ></a>-->
                <router-link to="/checkout" class="btn style1"
                  >Proceed To Checkout <i class="lar la-check-circle"></i
                ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import { baseUrl } from "@/store/service";
import Header from "@/views/layouts/Header.vue";
import Footer from "@/views/layouts/Footer.vue";
import { getLocalStorage, tipTax } from "@/store/service";
import { checkCoupon, getSettings, getRestaurantInfo } from "@/store/api";
export default {
  name: "cart",
  data() {
    return {
      user: null,
      showButton: null,
      storeInfo: null,
      shape1: require("@/assets/img/shape-1.png"),
      shape2: require("@/assets/img/shape-2.png"),
      thumb1: require("@/assets/img/blog/post-thumb-1.jpg"),
      thumb2: require("@/assets/img/blog/post-thumb-2.jpg"),
      thumb3: require("@/assets/img/blog/post-thumb-3.jpg"),
      coupon_applied: "",
      add_quantity: [],
      deliveryCharges: 0,
      couponDetail: "",
      discountPrice: 0,
      discountLimit: "",
      discountPercent: 0,
      item: [],
      tipTax: {
        tips: {},
        taxPercentage: {},
        tipsvalue: [],
      },
      orderTotal: 0,
      taxes: [],
      taxTotal: 0,
      totalAmount: 0,
      form: {
        coupon: "",
      },
      submitOrder: {
        user: {
          data: "",
        },
        order: [],
        coupon: "",
        quantity: 0,
        location: {
          lat: "",
          lng: "",
          address: "",
          house: null,
          tag: null,
        },
        order_comment: null,
        total: {
          productQuantity: "",
          totalPrice: "",
          tip_to_driver: "",
        },
        method: "COD",
        payment_token: "",
        delivery_type: "",
        partial_wallet: "",
        dis: 0,
        pending_payment: "",
        tipAmount: 0,
      },
    };
  },
  components: {
    Header,
    Footer,
  },
  mounted() {
    this.user = getLocalStorage("userData");
    this.getResInfo();
    if (getLocalStorage("cart")) {
      this.showItem();
      this.getSetting();
      console.log(this.submitOrder.order);
    } else {
      this.$router.push("/shop");
    }
  },
  methods: {
    getResInfo() {
      getRestaurantInfo().then((res) => {
        this.storeInfo = res.data;
        if (!this.user) {
          if (this.storeInfo.open === 1) {
            this.showButton = true;
          } else {
            this.showButton = false;
          }
        } else {
          if (this.user && !this.user.role) {
            console.log("user");
            if (this.storeInfo.open === 1) {
              this.showButton = true;
            } else {
              this.showButton = false;
            }
          } else {
            if (this.storeInfo.table_order_open === 1) {
              this.showButton = true;
            } else {
              this.showButton = false;
            }
          }
          console.log(this.showButton);
          if (this.showButton === false) {
            this.$toast.error("Restaurant is now closed.", {
              timeout: 1500,
            });
            this.$router.push("/shop");
          }
        }
      });
    },
    baseUrl() {
      return baseUrl();
    },
    showItem() {
      this.item = getLocalStorage("cart");
      this.submitOrder.order = getLocalStorage("cart");
      for (var i = 0; i < this.item.length; i++) {
        this.add_quantity[i] = this.item[i].quantity;
      }
    },
    addQuantity(index) {
      var storedNames = JSON.parse(localStorage.getItem("cart"));
      var name = [];
      for (var j = 0; j < storedNames.length; j++) {
        if (j === index) {
          this.add_quantity[index] = storedNames[index].quantity++;
          name.push(storedNames[j]);
        } else {
          name.push(storedNames[j]);
        }
      }
      localStorage.removeItem("cart");
      localStorage.setItem("cart", JSON.stringify(name));
      this.showItem();
      console.log(this.add_quantity[index]);
    },
    minusQuantity(item, index) {
      if (item.quantity > 1) {
        var storedNames = JSON.parse(localStorage.getItem("cart"));
        var name = [];
        // var name = storedNames.slice(index, 1)
        // localStorage.setItem('cart', JSON.stringify(name))
        for (var j = 0; j < storedNames.length; j++) {
          if (j === index) {
            this.add_quantity[index] = storedNames[index].quantity--;
            name.push(storedNames[j]);
          } else {
            name.push(storedNames[j]);
          }
        }
        localStorage.removeItem("cart");
        localStorage.setItem("cart", JSON.stringify(name));
        this.showItem();
      }
    },
    getSetting() {
      getSettings().then((res) => {
        this.tipTax.taxPercentage = res.data[45];
        this.tipTax.tips = res.data[109];
        this.tipTax.tipsvalue = res.data[109].value.split(",");
        tipTax("taxes", JSON.stringify(this.tipTax));
        this.taxes = getLocalStorage("taxes");
        for (var i = 0; i < this.item.length; i++) {
          if (this.item[i].addOnTotal) {
            this.orderTotal +=
              parseInt(this.item[i].quantity) *
              parseFloat(this.item[i].addOnTotal);
          } else {
            this.orderTotal +=
              parseInt(this.item[i].quantity) * parseFloat(this.item[i].price);
          }
        }
        this.taxTotal =
          ((parseFloat(this.orderTotal) - parseFloat(this.discountPrice)) *
            parseInt(this.taxes.taxPercentage.value)) /
          100;
        console.log(this.discountPrice);
        this.totalAmount =
          parseFloat(this.orderTotal) -
          parseFloat(this.discountPrice) +
          parseFloat(this.taxTotal);
        this.submitOrder.total.totalPrice = this.totalAmount;
      });
    },
    deleteItem(index) {
      var storedNames = JSON.parse(localStorage.getItem("cart"));
      var name = [];
      // var name = storedNames.slice(index, 1)
      // localStorage.setItem('cart', JSON.stringify(name))
      for (var j = 0; j < storedNames.length; j++) {
        if (j !== index) {
          name.push(storedNames[j]);
        }
      }
      localStorage.removeItem("cart");
      localStorage.setItem("cart", JSON.stringify(name));
      this.showItem();
    },
    couponVerify() {
      const data = {
        coupon: this.form.coupon,
        subTotal: this.orderTotal,
      };
      checkCoupon(data).then((res) => {
        console.log(res.data);
        if (res.data.success === false) {
          this.discountLimit = res.data.message ?? "Coupon not available";
        } else {
          this.couponDetail = res.data;
          this.submitOrder.coupon = {
            code: res.data.code,
          };
          if (
            res.data.discount_type === "AMOUNT" &&
            this.orderTotal >= res.data.min_subtotal
          ) {
            this.discountPrice = res.data.discount;
            this.coupon_applied = "Coupon Applied";
          } else if (
            res.data.discount_type === "PERCENTAGE" &&
            this.orderTotal >= res.data.min_subtotal
          ) {
            this.discountPercent = res.data.discount;
            this.discountPrice = (this.discountPercent / 100) * this.orderTotal;
            this.coupon_applied = "Coupon Applied";
          } else if (this.orderTotal < res.data.min_subtotal) {
            this.discountLimit =
              "Your order value should be atleast $" + res.data.min_subtotal;
          }
          this.getSetting("coupon");
        }
      });
    },
  },
};
</script>
<style>
.wh_item img {
  width: 100% !important;
  height: 100% !important;
}
.wishlist-table table tbody tr td {
  padding: 10px 19px;
  text-align: center;
  border: none;
  font-size: 14px;
}
span.qtyValue {
  border-radius: 20% !important;
  margin-left: 66px;
}
.hidden-xs {
  display: block;
}
.hidden-md {
  display: none;
}
@media screen and (max-width: 480px) {
  .hidden-xs {
    display: none;
  }
  .hidden-md {
    display: block;
  }
  button.button-close {
    background: white;
    border: none;
  }
  button.button-close:hover {
    background: rgb(240, 93, 93);
    border: none;
    color: #fff;
  }
  .col-md-6.box {
    width: 50% !important;
  }
  h5.heading-cart {
    font-size: 13px;
  }
  .product-quantity.style2 .qtySelector span.decreaseQty {
    left: 22px;
  }
  .product-quantity.style2 .qtySelector span.increaseQty {
    right: 30px;
  }
  .wh_qty {
    position: relative;
    right: 25px;
    top: 3px;
  }
  button.button-close.text-danger.text-end {
    float: right;
    position: relative;
    left: 39px;
    top: 0px;
  }
  .badge.remave {
    font-size: 14px !important;
    position: relative;
    top: -32px;
    left: 57px;
    cursor: default;
  }
  .qtySelector {
    position: relative;
    right: 5pc;
  }
}
</style>
